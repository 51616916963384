export const modules = [
    {
        title: "Atrações",
        link: "/atracoes"
    },
    {
        title: "Acomodações",
        link: "/acomodacoes"
    },
    {
        title: "Comodidades",
        link: "/comodidades"
    },
    {
        title: "Pontos Turísticos",
        link: "/pontos-turisticos"
    }
]